import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import news6pic1 from '../assets/images/news6pic1.jpg'
import ios from '../assets/images/app-store.svg'
import google from '../assets/images/google-play.svg'
const News6 = props => (
  <Layout>
    <Helmet>
      <title>三健客 3musclers - 配合秋冬防疫專案</title>
      <meta name="description" content="配合秋冬防疫專案" />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>配合秋冬防疫專案</h1>
          </header>
          <div className="box alt">
            <div className="grid-wrapper">
              <div className="col-4">
                <span className="image main">
                  <img src={news6pic1} alt="" />
                </span>
              </div>
            </div>
          </div>
          <h2>【📢 重要公告！】​</h2>
          <p>
            根據政府頒布「秋冬防疫專案」規定，
            自12月1日起，#進出健身房需配戴口罩
          </p>
          <p>提醒健客們📣 進館內前務必要戴口罩、手部消毒和量額溫🖐🏼</p>
          <p>
            訓練期間可以將口罩拿下來， 其餘時間包含休息、伸展都要記得將口罩戴好!
            戴口罩、勤洗手、多運動保持身體健康 </p>
				<p>防疫之路我們一起走💪🏽</p>
				<iframe
              src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2F3muscler%2F&width=450&layout=standard&action=like&size=small&share=true&height=35&appId=3212226998840909"
              width="450"
              height="35"
              scrolling="no"
              frameborder="0"
              allowTransparency="true"
              allow="encrypted-media"
            ></iframe>
        </div>
      </section>
    </div>
  </Layout>
)

export default News6
